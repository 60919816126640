import posthog from 'posthog-js';

function initializePostHog() {
  if (shouldExcludePosthog()) {
    return;
  }

  posthog.init(process.env.PUBLIC_POSTHOG_KEY, {
    api_host: 'https://app.posthog.com',
    loaded(posthogObj) {
      const data = window.dataLayer.find((item) => {
        const keyNames = Object.keys(item);
        return keyNames.includes('USER') && keyNames.includes('COMPANY');
      });

      if (!data) {
        console.error('Error: USER and COMPANY data not found in window.dataLayer.');
        return;
      }

      const { USER, COMPANY } = data;

      try {
        posthogObj.identify(USER.EMAIL);

        posthogObj.people.set({
          User_ID:               USER.ID_IN_DB,
          User_Name:             USER.NAME,
          User_Email:            USER.EMAIL,
          User_Role:             USER.ROLE,
          Company_Name:          COMPANY.NAME,
          Organization_Name:     COMPANY.ORGANIZATION_NAME,
          Account_Number:        COMPANY.ID_IN_DB,
          Organization_Number:   COMPANY.ORGANIZATION_ID,
          Pricing_Plan_Name:     COMPANY.PLAN_NAME,
          Account_Creation_Date: COMPANY.SIGN_UP_DATE,
          Pricing_Plan_ID:       COMPANY.PRICING_PLAN_ID,
          Trial:                 COMPANY.TRIAL,
          Active:                COMPANY.ACTIVE,
          Test:                  COMPANY.TEST_ACCOUNT,
        });

        posthog.capture('web_experiment_applied');
      } catch (error) {
        console.error('Error during PostHog user identification or setting properties:', error);
      }

      window.isPosthogInitialized = true;
      window.dispatchEvent(new Event('posthogReady'));
    },
  });

  window.posthog = posthog;
}

function shouldExcludePosthog() {
  const url = window.location.href;
  const exception_pages = ['/houston/', '/t/', 'users/sign_in', '/video/'];

  const shouldExclude = exception_pages.some((keyword) => url.includes(keyword));

  return shouldExclude;
}

export default initializePostHog;
