import Rails              from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import { Amplify }        from 'aws-amplify';
import '@fortawesome/fontawesome-pro/js/all';

import initializePostHog from '../src/lib/posthog-init';
import config             from '~/init/aws-exports';

// This will initialize PostHog if the conditions are met
initializePostHog();

function requireAll(r) { r.keys().forEach(r); }

Rails.start();
ActiveStorage.start();
Amplify.configure(config);

const componentRequireContext = require.context('../src/components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

_lodash.mixin(require('lodash-inflection'));

require('moment-duration-format');

window.Cookies              = require('js.cookie');
window.Moment               = require('moment-timezone');
window.bs                   = require('bootstrap');

import('../src/components/global_container').then(
  (globalContainerModule) => {
    window.GlobalContainer = globalContainerModule.default;
  },
);

import('../src/components/app_router').then((appRouterModule) => {
  window.AppRouter = appRouterModule.default;
});

import('../src/components/billing_app').then((billingAppModule) => {
  window.BillingApp = billingAppModule.default;
});

import('../src/components/onboard_app').then((onboardAppModule) => {
  window.OnboardApp = onboardAppModule.default;
});

import('../src/components/notifications').then(
  (notificationsModule) => {
    window.Notifications = notificationsModule.default;
  },
);

import('../src/components/messages').then((messagesModule) => {
  window.Messages = messagesModule.default;
});

import('../src/components/attention_needed').then(
  (attentionNeededModule) => {
    window.AttentionNeeded = attentionNeededModule.default;
  },
);

import('../src/components/lead_recruiting_app').then(
  (leadRecruitingAppModule) => {
    window.LeadRecruitingApp = leadRecruitingAppModule.default;
  },
);

import('history').then((historyModule) => {
  window.browserHistory = historyModule.createBrowserHistory();
});

requireAll(require.context('../src/init', true, /\.(js)$/));

Moment.tz.setDefault(Moment.tz.guess(true));

window.height = 'auto';
